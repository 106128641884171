import React, { useState, useRef } from "react";
import useOnClickOutside from "@helpers/use-on-click-outside";
import ArrowDown from "@svg-icons/arrow-down";
import {
  Group,
  MenuName,
  ArrowContainer,
  NavWrapper,
  NavLink,
  NavGatsbyLink,
  NavLinkBlock,
  NavLinkTitle,
  NavLinkDescription,
} from "./menu.styled";

const DropdownMenu = ({ menuName, linksList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const closeGroups = e => {
    if (e && e.target.dataset.el !== "menu-button") {
      setIsOpen(false);
    }
  };

  const onGroupClick = () => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <Group
      ref={ref}
      key={menuName}
      data-el="menu-button"
      onClick={() => onGroupClick()}
    >
      <MenuName data-el="menu-button">{menuName}</MenuName>
      <ArrowContainer data-el="menu-button" isArrowRotated={isOpen}>
        <ArrowDown dataEl="menu-button" />
      </ArrowContainer>
      {isOpen ? (
        <NavWrapper>
          {linksList.map(({ linkName, linkUrl, linkDescription }) => {
            if (linkUrl.includes("#")) {
              return (
                <NavLink
                  key={linkName}
                  to={linkUrl}
                  onAnchorLinkClick={closeGroups}
                >
                  <NavLinkBlock>
                    <NavLinkTitle>{linkName}</NavLinkTitle>
                    {linkDescription && (
                      <NavLinkDescription>{linkDescription}</NavLinkDescription>
                    )}
                  </NavLinkBlock>
                </NavLink>
              );
            }
            return (
              <NavGatsbyLink key={linkName} to={linkUrl}>
                <NavLinkBlock>
                  <NavLinkTitle>{linkName}</NavLinkTitle>
                  {linkDescription && (
                    <NavLinkDescription>{linkDescription}</NavLinkDescription>
                  )}
                </NavLinkBlock>
              </NavGatsbyLink>
            );
          })}
        </NavWrapper>
      ) : (
        ""
      )}
    </Group>
  );
};

export default DropdownMenu;
