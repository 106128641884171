import React from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import Header from "@components/header";
import Footer from "@components/footer";
import CookiesBanner from "@components/cookies-banner";
import { Container } from "./layout.styled";

import "./layout.css";

const Layout = ({ children }) => {
  const [cookies] = useCookies();

  return (
    <Container>
      <Header />
      <main>{children}</main>
      <Footer />
      {cookies.isAccepted !== "true" && <CookiesBanner />}
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
