import React from "react";
import { ImageContainer, StyledImage, StyledGatsbyImage } from "./image.styled";

const Image = ({ style, objectFit = 'cover', image, width, height, isFixed, alt, className }) => {
  if (!image) return null;
  if (image.childImageSharp) {
    const gatsbyImageProps = isFixed
      ? {
          fixed: image.childImageSharp.fixed,
        }
      : {
          fluid: image.childImageSharp.fluid,
        };
    return (
      <ImageContainer $width={width} $height={height} className={className}>
        <StyledGatsbyImage
          alt={alt}
          {...gatsbyImageProps}
          style={style}
          objectFit={objectFit}
        />
      </ImageContainer>
    );
  }

  return (
    <ImageContainer $width={width} $height={height} className={className}>
      <StyledImage alt={alt} src={image.publicURL} style={style} />
    </ImageContainer>
  );
};

export default Image;
