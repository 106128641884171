import React from "react";
import * as Colors from "@constants/colors";
import ButtonLink from "@components/button-link";
import DropdownMenu from "./dropdown-menu";
import { MenuWrapper, SingleLink } from "./menu.styled";

const Menu = ({ buttonLabel, menuList = [], singleLinksList = [] }) => (
  <MenuWrapper>
    {menuList.map(({ menuName, linksList }) => (
      <DropdownMenu menuName={menuName} linksList={linksList} key={menuName} />
    ))}
    {singleLinksList.map(singleLink => (
      <SingleLink to={singleLink.linkUrl} key={singleLink.linkUrl}>
        {singleLink.linkName}
      </SingleLink>
    ))}
    <ButtonLink
      to="/contact"
      text={buttonLabel}
      color={Colors.goldenHourLighter}
      borderColor={Colors.goldenHourLighter}
      hoverColor={Colors.beachTeal}
      backgroundColor="transparent"
    />
  </MenuWrapper>
);

export default Menu;
