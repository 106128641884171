import React, { useState, useRef } from "react";
import * as Colors from "@constants/colors";
import useOnClickOutside from "@helpers/use-on-click-outside";
import ButtonLink from "@components/button-link";
import ButtonHamburger from "@components/button-hamburger";
import {
  MenuWrapper,
  Group,
  GroupName,
  SingleLink,
  GroupsWrapper,
  NavLink,
  NavGatsbyLink,
  ButtonContainer,
} from "./mobile-menu.styled";

const MobileMenu = ({ buttonLabel, menuList = [], singleLinksList = [] }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsMenuOpen(false));

  const closeMenu = () => setIsMenuOpen(false);

  const toggleMenu = () =>
    isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);

  return (
    <MenuWrapper ref={ref}>
      <ButtonHamburger onClick={toggleMenu} isMenuOpen={isMenuOpen} />
      {isMenuOpen && (
        <GroupsWrapper>
          {menuList.map(({ menuName, linksList }) => (
            <Group key={menuName}>
              <GroupName>{menuName}</GroupName>
              {linksList.map(({ linkName, linkUrl }) => {
                if (linkUrl.includes("#")) {
                  return (
                    <NavLink
                      key={linkName}
                      to={linkUrl}
                      onAnchorLinkClick={closeMenu}
                    >
                      {linkName}
                    </NavLink>
                  );
                }
                return (
                  <NavGatsbyLink key={linkName} to={linkUrl}>
                    {linkName}
                  </NavGatsbyLink>
                );
              })}
            </Group>
          ))}
          {singleLinksList.map(singleLink => (
            <SingleLink to={singleLink.linkUrl} key={singleLink.linkUrl}>
              {singleLink.linkName}
            </SingleLink>
          ))}
          <ButtonContainer>
            <ButtonLink
              to="/contact"
              text={buttonLabel}
              color={Colors.goldenHourLighter}
              borderColor={Colors.goldenHourLighter}
              backgroundColor="transparent"
            />
          </ButtonContainer>
        </GroupsWrapper>
      )}
    </MenuWrapper>
  );
};

export default MobileMenu;
