import styled from "styled-components";
import { Link } from "gatsby";
import * as Colors from "@constants/colors";

const Container = styled.header`
  background: ${Colors.charcoal};
  height: 72px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const LogoContainer = styled(Link)`
  max-width: 124px;
`;

export { Container, Content, LogoContainer };
