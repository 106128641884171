import styled from "styled-components";
import * as Colors from "@constants/colors";
import { ProximaNovaFont } from "@constants/fonts";

const Container = styled.div`
  padding-top: 72px;
  color: ${Colors.charcoal};
  font-family: ${ProximaNovaFont};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.white};
`;

export { Container };
