import React from "react";
import { useCookies } from "react-cookie";
import { graphql, useStaticQuery } from "gatsby";
import convertMarkdownToHtml from "@helpers/convert-markdown-to-html";
import { Wrapper } from "@components/shared.styled";
import {
  Container,
  Content,
  Description,
  Button,
} from "./cookies-banner.styled";

const CookiesBanner = () => {
  /* eslint-disable */
  const [cookies, setCookie] = useCookies();
  /* eslint-enable */

  const {
    markdownRemark: {
      frontmatter: { bannerBackground, description, buttonLabel },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/cookies-banner/" }) {
          frontmatter {
            bannerBackground
            description
            buttonLabel
          }
        }
      }
    `
  );

  const onButtonClick = () => {
    setCookie("isAccepted", true, { path: "/" });
  };

  return (
    <Container containerBackground={bannerBackground}>
      <Wrapper>
        <Content>
          <Description
            dangerouslySetInnerHTML={{
              __html: convertMarkdownToHtml(description),
            }}
          />
          <Button onClick={onButtonClick}>{buttonLabel}</Button>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default CookiesBanner;
