import styled, { css } from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import { ButtonLink } from "@components/button-link/button-link.styled";

const GroupsWrapper = styled.div`
  padding: 66px 40px;
  position: absolute;
  top: 72px;
  right: 0;
  background: ${Colors.charcoal};
  overflow: auto;
  max-height: calc(100vh - 72px);
  width: 100%;
  text-align: center;
`;

const Group = styled.div`
  color: ${Colors.goldenHourLighter};
  position: relative;
  user-select: none;
  margin-bottom: 52px;
`;

const GroupName = styled.p`
  margin: 0 0 24px;
  color: ${Colors.gray};
  text-transform: uppercase;
  font-weight: 600;
`;

const SingleLink = styled(Link)`
  display: block;
  margin: 0 0 52px;
  color: ${Colors.goldenHourLighter};
  text-decoration: none;
  text-transform: uppercase;
`;

const ShareLinkStyles = css`
  display: block;
  margin-bottom: 10px;
  color: ${Colors.white};
  text-decoration: none;
  font-size: 20px;

  :first-child {
    margin-top: 10px;
  }
`;

const NavLink = styled(AnchorLink)`
  ${ShareLinkStyles}
`;

const NavGatsbyLink = styled(Link)`
  ${ShareLinkStyles}
`;

const MenuWrapper = styled.div`
  display: none;

  @media (max-width: ${Screens.md}) {
    display: block;
  }

  ${ButtonLink} {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 14px;
    border-color: ${Colors.goldenHourLighter};
    color: ${Colors.goldenHourLighter};
    background: transparent;
    display: block;

    :hover {
      color: ${Colors.beachTeal};
      border-color: ${Colors.beachTeal};
    }
  } ;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export {
  MenuWrapper,
  GroupsWrapper,
  Group,
  GroupName,
  SingleLink,
  NavLink,
  NavGatsbyLink,
  ButtonContainer,
};
