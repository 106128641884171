import React from "react";
import * as Colors from "@constants/colors";

const ArrowDown = ({
  width = 10,
  height = 7,
  color = Colors.goldenHourLighter,
  dataEl,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 10.703 7.259"
    data-el={dataEl}
  >
    <path
      d="M-875.785,84.515l4.435,5.085,4.786-5.085"
      transform="translate(876.538 -83.83)"
      fill="none"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

export default ArrowDown;
