import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "@components/image";
import { Wrapper } from "@components/shared.styled";
import { Menu, MobileMenu } from "@components/menu";
import { Container, Content, LogoContainer } from "./header.styled.js";

const Header = () => {
  const {
    markdownRemark: { frontmatter },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/header/" }) {
          frontmatter {
            logoImage {
              publicURL
              childImageSharp {
                fixed(width: 124, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            menuList {
              linksList {
                linkDescription
                linkName
                linkUrl
              }
              menuName
            }
            singleLinksList {
              linkName
              linkUrl
            }
            buttonLabel
          }
        }
      }
    `
  );

  return (
    <Container>
      <Wrapper>
        <Content>
          <LogoContainer to="/">
            <Image
              image={frontmatter.logoImage}
              isFixed
              width="100%"
              alt="Logo"
            />
          </LogoContainer>
          <Menu {...frontmatter} />
          <MobileMenu {...frontmatter} />
        </Content>
      </Wrapper>
    </Container>
  );
};

export default Header;
