import styled from "styled-components";
import { Link } from "gatsby";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";

const Container = styled.footer`
  background-color: ${Colors.charcoal};
  color: ${Colors.white};
  padding: 80px 0 20px;
  margin-top: auto;

  @media (max-width: ${Screens.lg}) {
    padding: 50px 0;
  }
`;

const UpperPart = styled.div`
  margin-bottom: 68px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${Screens.lg}) {
    margin-bottom: 50px;
  }

  @media (max-width: ${Screens.md}) {
    flex-direction: column;
  }
`;

const ContactInfo = styled.div`
  width: 100%;
  max-width: 256px;
  margin: 9px 0 30px 0;

  @media (max-width: ${Screens.md}) {
    text-align: center;
    max-width: 100%;
    padding-bottom: 50px;
    margin: 0 0 50px;
    border-bottom: 1px solid ${Colors.brownLighter};
  }
`;

const LogoContainer = styled.div`
  width: 72px;
  margin: 0 0 32px;

  @media (max-width: ${Screens.md}) {
    margin: 0 auto 32px;
  }
`;

const ContactText = styled.p`
  margin: 0 0 10px;

  a {
    color: ${Colors.white};
    text-decoration: none;
  }
`;

const Menu = styled.div`
  display: flex;
  max-width: 100%;

  @media (max-width: ${Screens.md}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const MenuNav = styled.nav`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Screens.md}) {
    margin-bottom: 32px;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${Colors.white};
  line-height: 24px;
  margin-bottom: 12px;
  text-decoration: none;

  :hover {
    color: ${Colors.beachTeal};
  }
`;

const MenuColumn = styled.div`
  width: ${({ width = "163" }) => `${width}px;`}
  margin-right: 29px;
  order: ${({ itemOrder }) => itemOrder};
  
  :last-of-type {
    margin: 0;
  }

  @media (max-width: ${Screens.md}) {
    margin-right: 0;
    text-align: center;

    :nth-child(2) {
      ${MenuNav} {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
      }

      ${StyledLink} {
        width: 163px;
        margin-right: 0px;

        :nth-of-type(1) {
          order: 1;
        }
        :nth-of-type(2) {
          order: 3;
        }
        :nth-of-type(3) {
          order: 5;
        }
        :nth-of-type(4) {
          order: 2;
        }
        :nth-of-type(5) {
          order: 4;
        }
      }
    }

    :nth-child(3) {
      order: 2;
    }
    
    :last-child {
      width: 100%;
      padding-top: 50px;
      border-top: 1px solid ${Colors.brownLighter};
      margin-top: 50px;
    }
  }

  @media (max-width: ${Screens.sm}) {
    width: 100%;
  }
`;

const MenuTitle = styled.p`
  font-size: 14px;
  margin: 0 0 20px;
  color: ${Colors.gray};
  letter-spacing: 1.6px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;

const SubscribeDescription = styled.p`
  margin: 0 0 16px;
  line-height: 24px;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 260px;

  @media (max-width: ${Screens.md}) {
    margin: 0 auto;
  }
`;

const TextInput = styled.input`
  border: none;
  height: 45px;
  background: ${Colors.brownDarker};
  color: ${Colors.white};
  padding: 4px 50px 0 15px;
  width: 100%;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: ${Colors.white};
    font-size: 16px;
    line-height: 25px;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: ${Colors.white};
    font-size: 16px;
    line-height: 24px;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: ${Colors.white};
    font-size: 16px;
    line-height: 24px;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: ${Colors.white};
    font-size: 16px;
    line-height: 24px;
  }
`;

const FormButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 15px;
  top: 12px;
  cursor: pointer;
`;

const ThanksMessage = styled.p`
  color: ${Colors.beachTeal};
  margin: 16px 0;
`;

const BottomPart = styled.div`
  border-top: 1px solid ${Colors.brownLighter};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 0;

  @media (max-width: ${Screens.lg}) {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.p`
  @media (max-width: ${Screens.lg}) {
    margin: 41px 0 0;
  }
`;

const PrivacyPolicyLink = styled(Link)`
  color: ${Colors.white};
  cursor: pointer;

  @media (max-width: ${Screens.lg}) {
    margin: 27px 0 0;
  }
`;

export {
  Container,
  UpperPart,
  ContactInfo,
  LogoContainer,
  ContactText,
  Menu,
  MenuColumn,
  MenuTitle,
  MenuNav,
  StyledLink,
  SubscribeDescription,
  InputContainer,
  TextInput,
  FormButton,
  ThanksMessage,
  BottomPart,
  Text,
  PrivacyPolicyLink,
};
