import React from "react";
import { Container, LinesContainer, Line } from "./button-hamburger.styled";

const ButtonHamburger = ({ onClick, isMenuOpen }) => {
  return (
    <Container onClick={onClick} isMenuOpen={isMenuOpen}>
      <LinesContainer>
        <Line />
        <Line />
        <Line />
      </LinesContainer>
    </Container>
  );
};

export default ButtonHamburger;
