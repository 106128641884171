import styled, { keyframes, css } from "styled-components";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";
import { ButtonLink } from "@components/button-link/button-link.styled";

const fadeInAnimation = keyframes`
    0% {
      transform: translateY(-16px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1.0;
    }
`;

const Group = styled.div`
  color: ${Colors.goldenHourLighter};
  margin-right: 20px;
  position: relative;
  cursor: pointer;
  user-select: none;
`;

const MenuName = styled.span`
  margin-right: 10px;
`;

const SingleLink = styled(Link)`
  margin-right: 20px;
  color: ${Colors.goldenHourLighter};
  text-decoration: none;
`;

const ArrowContainer = styled.div`
  transition: all 0.2s;
  ${({ isArrowRotated }) =>
    isArrowRotated ? `transform: rotateX(180deg)` : ""};
  display: inline-block;
`;

const NavWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 ${fadeInAnimation};
  position: absolute;
  background: ${Colors.white};
  top: 40px;
  left: -120px;
  width: 240px;
  border-radius: 5px;
  box-shadow: 0 3px 16px #00000026;
`;

const NavLinkBlock = styled.div`
  padding-top: 16px;
`;

const ShareLinkStyles = css`
  display: block;
  text-decoration: none;
  padding: 0 24px 16px;
  color: ${Colors.charcoal};

  :hover {
    background-color: ${Colors.charcoal};
    color: ${Colors.white};
  }

  :hover + & ${NavLinkBlock} {
    border-top-color: ${Colors.white};
  }

  :hover p {
    color: ${Colors.white};
  }

  :last-of-type {
    padding-bottom: 24px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  :first-of-type ${NavLinkBlock} {
    padding-top: 24px;
  }
`;

const NavLink = styled(AnchorLink)`
  ${ShareLinkStyles}
`;

const NavGatsbyLink = styled(Link)`
  ${ShareLinkStyles}
`;

const NavLinkTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const NavLinkDescription = styled.p`
  font-size: 14px;
  color: ${Colors.gray};
  line-height: 20px;
  margin: 10px 0 0;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${Screens.md}) {
    display: none;
  }

  ${ButtonLink} {
    font-size: 16px;
    line-height: 16px;
    padding: 12px 14px;
    border-color: ${Colors.goldenHourLighter};
    color: ${Colors.goldenHourLighter};
    background: transparent;

    :hover {
      color: ${Colors.beachTeal};
      border-color: ${Colors.beachTeal};
    }
  } ;
`;

export {
  Group,
  MenuWrapper,
  MenuName,
  SingleLink,
  ArrowContainer,
  NavWrapper,
  NavLink,
  NavGatsbyLink,
  NavLinkTitle,
  NavLinkBlock,
  NavLinkDescription,
};
