import styled from "styled-components";
import * as Colors from "@constants/colors";

const LinesContainer = styled.div`
  position: relative;
  width: 32px;
  height: 26px;
`;

const Line = styled.div`
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  top: 0;
  background: ${Colors.goldenHourLighter};
  transition: 0.2s all;

  :nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  :nth-of-type(3) {
    top: auto;
    bottom: 0;
  }
`;

const Container = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;

  ${({ isMenuOpen }) =>
    isMenuOpen
      ? `
    ${Line} {
      top: 50%;
      transform: rotate(45deg) translateY(-50%);

      :nth-of-type(2) {
        opacity: 0;
      }
      
      :nth-of-type(3) {
        top: 50%;
        transform: rotate(-45deg) translateY(-50%);
      }
    }
  `
      : ""}
`;

export { Container, LinesContainer, Line };
