import styled from "styled-components";
import Img from "gatsby-image";

const ImageContainer = styled.div`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

const imageStyles = `
  height: 100%;
  width: 100%;
`;

const StyledImage = styled.img`
  ${imageStyles};
`;

const StyledGatsbyImage = styled(Img)`
  ${imageStyles};
`;

export { ImageContainer, StyledGatsbyImage, StyledImage };
