import styled from "styled-components";
import * as Colors from "@constants/colors";
import { Screens } from "@constants/screens";

const Container = styled.div`
  background: ${({ containerBackground = Colors.seafoam }) =>
    containerBackground};
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 16px 0;
  z-index: 102;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${Screens.md}) {
    flex-direction: column;
    text-align: center;
  }
`;

const Description = styled.div`
  margin: 0 40px 0 0;
  max-width: 900px;
  line-height: 1.4;
  font-size: 14px;

  a {
    color: ${Colors.charcoal};
  }

  @media (max-width: ${Screens.md}) {
    margin: 0 0 16px 0;
  }
`;

const Button = styled.button`
  border: none;
  border-radius: 5px;
  background: ${Colors.charcoal};
  color: ${Colors.white};
  font-weight: 600;
  padding: 0 20px;
  height: 31px;
  cursor: pointer;
`;

export { Container, Content, Description, Button };
