import React from "react";
import { ButtonLink, ExternalButtonLink } from "./button-link.styled";

const ButtonLinkComponent = ({ text, to, isExternal, className }) =>
  isExternal ? (
    <ExternalButtonLink className={className} href={to} target="_blank">
      {text}
    </ExternalButtonLink>
  ) : (
    <ButtonLink to={to} className={className}>
      {text}
    </ButtonLink>
  );
export default ButtonLinkComponent;
