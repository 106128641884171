import styled from "styled-components";
import * as Colors from "@constants/colors";
import { ClearfaceFont, ProximaNovaFont } from "@constants/fonts";
import { Screens } from "@constants/screens";

const wrapperPadding = 20;
const Wrapper = styled.div`
  max-width: ${1124 + wrapperPadding * 2}px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  padding: 0 ${wrapperPadding}px;
`;

const Button = styled.button`
  border-radius: 5px;
  background: ${Colors.beachTeal};
  border: none;
  color: ${Colors.white};
  font-weight: 700;
  font-size: 24px;
  line-height: 25px;
  padding: 20px 34px 19px;
  cursor: pointer;

  @media (max-width: ${Screens.xs}) {
    padding: 10px 15px;
    font-size: 18px;
    line-height: 20px;
  }
`;

const BlockHeader = styled.h2`
  margin: 0 auto 24px;
  line-height: 1.3;
  font-size: 48px;
  color: ${({ color = Colors.charcoal }) => color};
  font-family: ${ClearfaceFont};
  text-align: center;

  @media (max-width: ${Screens.lg}) {
    font-size: 48px;
  }

  @media (max-width: ${Screens.md}) {
    font-size: 32px;
  }
`;

const BlockSupTitle = styled.h3`
  color: ${({ color = Colors.beachTeal }) => color};
  font-size: 13px;
  letter-spacing: 1.15px;
  margin: 0 auto 12px;
  text-transform: uppercase;

  @media (max-width: ${Screens.lg}) {
    font-size: 14px;
  }
`;

const BlockTitle = styled.h2`
  font-size: 36px;
  line-height: 1.3;
  font-family: ${ClearfaceFont};
  margin: 0 auto 90px;
  max-width: 770px;
  color: ${({ color = Colors.charcoal }) => color};

  @media (max-width: ${Screens.lg}) {
    margin-bottom: 60px;
    font-size: 32px;
  }

  @media (max-width: ${Screens.lg}) {
    font-size: 28px;
    margin-bottom: 48px;
  }
`;

const BlockDescription = styled.div`
  font-size: ${({ pageType }) => pageType === 'careers' ? 16 : 18}px;
  font-weight: normal;
  letter-spacing: 0.32px;
  line-height: 25px;
  margin: 0;

  @media (max-width: ${Screens.lg}) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: ${Screens.lg}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Quote = styled.p`
  font-size: 150px;
  font-weight: bold;
  font-family: ${ClearfaceFont};
  color: ${Colors.beachTeal};
  margin: 0;
`;

const InfoTable = styled.table`
  color: ${Colors.blackLighter};
  width: 100%;
  min-width: 560px;
  border-collapse: collapse;
  margin-bottom: 27px;
`;

const InfoCardsBlocks = styled.div`
  display: none;
`;

const Card = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin-bottom: 50px;
  width: 100%;
`;

const CardItem = styled.div`
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  border-bottom: 1px solid ${Colors.blackLighter};
  padding: 20px 0;

  :first-of-type {
    font-weight: bold;
  }
`;

const TableWrapper = styled.div`
  overflow: auto;

  @media (max-width: ${Screens.md}) {
    ${InfoTable} {
      display: none;
    }

    ${InfoCardsBlocks} {
      display: block;
    }
  }
`;

const TableHeaderCell = styled.th`
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: ${({ textAlign = "left" }) => textAlign};
  padding: 0 20px 24px 0;
  border-bottom: 3px solid ${Colors.blackLighter};

  width: ${({ width }) => (width ? width : "auto")};

  :last-of-type {
    padding-right: 0;
  }

  @media (max-width: ${Screens.md}) {
    padding: 0 10px 10px 0;
  }
`;

const TableRow = styled.tr`
  border-bottom: 1px solid ${Colors.blackLighter};
  cursor: pointer;

  :hover {
    box-shadow: 0 6px 4px -5px ${Colors.blackLighter};
  }
`;

const TableCell = styled.td`
  font-size: 18px;
  line-height: 26px;
  text-align: ${({ textAlign = "left" }) => textAlign};
  max-width: 585px;
  padding: 24px 20px 24px 0;

  :last-of-type {
    padding-right: 0;
  }

  @media (max-width: ${Screens.lg}) {
    padding: 20px 20px 20px 0;
    font-size: 18px;
    line-height: 24px;
  }
`;

const ViewMoreButton = styled.button`
  border: none;
  outline: none;
  color: ${Colors.charcoal} ${ProximaNovaFont};
  font-size: 18px;
  text-decoration: underline;
  background: transparent;
  cursor: pointer;
  margin-bottom: 50px;
  padding: 5px;

  :hover {
    text-decoration: none;
  }
`;

export {
  Wrapper,
  Button,
  BlockHeader,
  BlockSupTitle,
  BlockTitle,
  BlockDescription,
  Quote,
  InfoCardsBlocks,
  Card,
  CardItem,
  TableWrapper,
  InfoTable,
  TableHeaderCell,
  TableRow,
  TableCell,
  ViewMoreButton,
};
