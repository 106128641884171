import styled from "styled-components";
import { Link } from "gatsby";
import { Screens } from "@constants/screens";
import * as Colors from "@constants/colors";

export const linkStyles = `
  border-radius: 5px;
  border: 1px solid ${Colors.beachTeal};
  background: ${Colors.beachTeal};
  color: ${Colors.white};
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  padding: 16px 24px;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;

  :hover {
    background: ${Colors.beachTealDarker};
    border-color: ${Colors.beachTealDarker};
  }

  @media (max-width: ${Screens.sm}) {
    padding: 10px 15px;
  }
`;

const ButtonLink = styled(Link)`
  ${linkStyles}
`;

const ExternalButtonLink = styled.a`
  ${linkStyles}
`;

export { ButtonLink, ExternalButtonLink };
