export const beachTealLighter = "#DAF5F0";
export const beachTeal = "#2BBFAA";
export const beachTealDarker = "#1eaf9b";
export const charcoal = "#231F20";
export const black = "#222222";
export const blackLighter = "#333333";
export const brownLighter = "#5C5557";
export const brownLighter2 = "#817B7D";
export const brownDarker = "#393637";
export const seafoam = "#89DAC0";
export const seafoamLite = "#B5E2D9";
export const seafoamLighter = "#EAFAF7";
export const goldenHourLighter = "#FDF6EA";
export const goldenHourLight = "#EFD6B1";
export const goldenHour = "#F5B336";
export const gray = "#797979";
export const grayLighter = "#a39fa0";
export const grayDarker = "#707070";
export const white = "#FFFFFF";
export const offWhite = "#FAF8F9";
export const sunsetCoral = "#EB7969";
export const primary = "#2DB29E";
export const light = "#EAF9F7";
