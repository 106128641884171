import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Image from "@components/image";
import { Wrapper } from "@components/shared.styled";
import encode from "@helpers/encode";
import {
  Container,
  UpperPart,
  ContactInfo,
  LogoContainer,
  ContactText,
  Menu,
  MenuColumn,
  MenuTitle,
  MenuNav,
  StyledLink,
  SubscribeDescription,
  InputContainer,
  TextInput,
  FormButton,
  ThanksMessage,
  BottomPart,
  Text,
  PrivacyPolicyLink,
} from "./footer.styled";

const Footer = () => {
  const {
    markdownRemark: {
      frontmatter: {
        cInfo,
        contactInfo,
        menuList,
        privacyPolicy,
        subscribeInfo,
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/footer/" }) {
          frontmatter {
            contactInfo {
              logoImage {
                publicURL
                childImageSharp {
                  fixed(width: 72, quality: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              logoAlt
              email
              emailLabel
              phone
              addressLine1
              addressLine2
            }
            menuList {
              linksList {
                linkDescription
                linkName
                linkUrl
              }
              menuName
            }
            subscribeInfo {
              description
              inputPlaceholder
              title
              sendIcon {
                publicURL
                childImageSharp {
                  fixed(width: 18, quality: 90) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              thanksMessage
            }
            cInfo
            privacyPolicy {
              text
              url
            }
          }
        }
      }
    `
  );

  const [thanksMessage, setThanksMessage] = useState(false);
  const [email, setEmail] = useState("");

  const onFormSubmit = e => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": e.target.getAttribute("name"),
        email,
      }),
    });

    document.getElementById("subscribe-form").reset();
    setThanksMessage(true);
  };

  return (
    <Container>
      <Wrapper>
        <UpperPart>
          <ContactInfo>
            <LogoContainer>
              <Image
                image={contactInfo.logoImage}
                alt={contactInfo.logoAlt}
                isFixed
                width="72px"
                height="56px"
              />
            </LogoContainer>
            <ContactText>
              <a
                href={`mailto:${contactInfo.email}`}
                target="_blank"
                rel="noreferrer"
              >
                {contactInfo.emailLabel}
              </a>
            </ContactText>
            <ContactText>
              <a href={`tel:+1${contactInfo.phone}`}>{contactInfo.phone}</a>
            </ContactText>
            <ContactText>{contactInfo.addressLine1}</ContactText>
            <ContactText>{contactInfo.addressLine2}</ContactText>
          </ContactInfo>
          <Menu>
            {menuList.map(({ menuName, linksList }, index) => (
              <MenuColumn itemOrder={index + 1} key={menuName}>
                <MenuTitle>{menuName}</MenuTitle>
                <MenuNav>
                  {linksList.map(({ linkName, linkUrl }) => (
                    <StyledLink key={linkUrl} to={linkUrl}>
                      {linkName}
                    </StyledLink>
                  ))}
                </MenuNav>
              </MenuColumn>
            ))}
            <MenuColumn itemOrder={menuList.length + 1} width="260">
              <MenuTitle>{subscribeInfo.title}</MenuTitle>
              <MenuNav>
                <SubscribeDescription>
                  {subscribeInfo.description}
                </SubscribeDescription>
                <form
                  name="Footer Newsletter Subscription"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={onFormSubmit}
                  id="subscribe-form"
                >
                  <input type="hidden" name="form-name" value="subscribe" />
                  <p hidden>
                    <label>
                      Don’t fill this out: <input name="bot-field" />
                    </label>
                  </p>
                  <InputContainer>
                    <TextInput
                      name="email"
                      id="email"
                      placeholder={subscribeInfo.inputPlaceholder}
                      type="email"
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                    <FormButton type="submit">
                      <Image
                        image={subscribeInfo.sendIcon}
                        isFixed
                        width="100%"
                        alt="Send button icon"
                      />
                    </FormButton>
                  </InputContainer>
                </form>
                {thanksMessage && (
                  <ThanksMessage>{subscribeInfo.thanksMessage}</ThanksMessage>
                )}
              </MenuNav>
            </MenuColumn>
          </Menu>
        </UpperPart>
        <BottomPart>
          <Text>{cInfo}</Text>
          {privacyPolicy && privacyPolicy.url && (
            <PrivacyPolicyLink to={privacyPolicy.url}>
              {privacyPolicy.text}
            </PrivacyPolicyLink>
          )}
        </BottomPart>
      </Wrapper>
    </Container>
  );
};

export default Footer;
